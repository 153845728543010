@import '../../variables';

.terms-page {
  .page-header {
    height: 400px;
    @include media-breakpoint-down(xs) {
      img {
        height: 220px !important;
      }
    }
    .title-wrapper {
      padding-top: 50px;
      text-align: center;
      justify-content: center;
      flex-direction: row;

      h1 {
        text-align: center;
        max-width: 600px;
      }
    }
  }

  h4 {
    font-size: 1.5rem;
  }
}
