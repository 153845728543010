@import '../../variables.scss';

.accordion-wrapper {
  .card {
    border: none;
    margin-bottom: 10px;
  }
  .card-header {
    cursor: pointer;
    padding: 30px;
    h5 {
      font-weight: 900;

      @include media-breakpoint-down(xs) {
        font-size: 1.1rem !important;
      }
    }
  }
  .card-body {
    border: none;
    padding: 0px;
    height: auto;
    .course-content-box {
      background-color: rgba($color: $fontDarkBlue, $alpha: 0.11);
      padding: 15px 55px;

      @include media-breakpoint-down(xs) {
        padding: 10px 25px;
      }
      p {
        margin-bottom: 0px;
        font-weight: 900;
      }
    }
    .course-unit-box {
      background-color: rgba($color: $fontDarkBlue, $alpha: 0.06);
      padding: 35px 55px;
      margin-top: 2px;

      @include media-breakpoint-down(xs) {
        padding: 20px 25px;
      }

      a {
        font-weight: 500;
        font-size: 18px;
        color: #093e5f;
        letter-spacing: 0;
        text-decoration: underline;
      }
    }
  }
}
