@import '../../variables';

.page-header {
  height: 650px;
  padding-top: 85px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  .title-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;

    h1 {
      font-size: 2.7rem;

      @include media-breakpoint-down(xs) {
        font-size: 2rem;
      }
    }

    h2 {
      font-size: 1.3rem;

      @include media-breakpoint-down(xs) {
        font-size: 1rem;
      }
    }
  }
}
