@import '../../variables.scss';
.date-of-birth-custom {
  .react-datepicker__month-dropdown-container {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 45%;
    select {
      width: 100%;
      text-indent: 10px;
    }
  }
  .react-datepicker__year-dropdown-container {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 45%;
    select {
      width: 100%;
      text-indent: 10px;
    }
  }
}
.Select--single > .Select-control .Select-value {
  overflow: initial;
}
.file-upload-label {
  width: 200px;
  height: 40px;
  color: #fff !important;
  background-color: #868e96;
  border-color: #868e96;
  border-radius: 0.15rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.course-pdf {
  width: 100%;
  height: 30px;
  padding: 20px;
  border: 1px solid rgba($color: #868e96, $alpha: 0.3);
  display: flex;
  align-items: center;
  .file-name {
    margin-right: 20px;
  }
  .cross {
    position: absolute;
    right: 30px;
    cursor: pointer;
  }
}
.apply-submission-status {
  flex-direction: column;
  justify-content: center;

  .title {
    font-weight: 900;
    font-size: 2rem;
    margin-bottom: 20px;
  }

  i {
    font-size: 3rem;
    color: #238bf8;
    margin-bottom: 25px;
  }

  .close {
    font-size: 0.9rem;
  }
}

.apply-wrapper {
  padding: 40px;
  min-height: 500px;

  input::-webkit-calendar-picker-indicator{
    display: none;
  }

  input[type="date"]::-webkit-input-placeholder{
    visibility: hidden !important;
  }

  @include media-breakpoint-down(xs) {
    padding: 0px;
    z-index: 8000;
  }

  img.topRight {
    @include media-breakpoint-down(xs) {
      height: 150px !important;
    }
  }

  img.topLeft {
    @include media-breakpoint-down(xs) {
      height: 200px !important;
    }
  }

  .react-datepicker {
    font-weight: 500;
  }

  .wrapper {
    z-index: 8000;
    position: relative;
  }

  .form-header {
    width: 70%;

    @include media-breakpoint-down(xs) {
      width: 100%;
    }

    .apply-tag {
      font-size: 1.5rem;
      font-weight: 600;

      @include media-breakpoint-down(xs) {
        font-size: 1.2rem;
      }
    }
    .course-title {
      font-weight: 900;
      font-size: 1.9rem;
      line-height: 34px;
      margin-top: 20px;
      margin-bottom: 30px;
      width: 100%;

      @include media-breakpoint-down(xs) {
        font-size: 1.5rem;
      }
    }
  }
  button {
    margin-bottom: 15px;
  }
  .button-container {
    button {
      &:first-of-type {
        margin-right: 5px;
      }
      &:last-of-type {
        margin-left: 5px;
      }
    }
  }

  .input {
    border: 1px solid rgba(9, 62, 95, 0.4) !important;
    margin-bottom: 5px !important;
  }

  .radio-wrapper {
    display: flex;
    border: 2px solid #bec0c8;
    border-radius: 3px;
    font-size: 0.9rem;
    letter-spacing: normal;
    margin-bottom: 15px;
    font-weight: 600;
    padding: 15px;
    &.active {
      border-color: #238bf8;
    }
    &:hover {
      cursor: pointer;
      border-color: #238bf8;
    }

    .radio-containter {
      width: 50px;
      height: 30px;

      input {
        font-size: unset !important;
        margin-right: 10px;
        @media screen and (min-width: 992px) {
          font-size: 2rem;
        }
      }
    }
    .text {
      font-weight: 500;
    }
  }

  .input-row {
    .error {
      font-weight: 700;
      text-transform: uppercase;
    }
  }

  .form-errors {
    font-weight: 600;
    border: 0px;
    font-size: 0.8rem;
  }

  input {
    width: 100%;
    height: 60px;
    padding: 15px 15px;
    border-radius: 3px;
    border: 1px solid #dddddd;
    background: #ffffff;
    margin-bottom: 5px;
    margin-top: 0px;

    &.radio {
      height: auto;
      margin: 0px;
    }
  }
}

.applyWrapper {
  input {
    border: 1px solid rgba(9, 62, 95, 0.4) !important;
  }
  .Select-control {
    border: 1px solid rgba(9, 62, 95, 0.4) !important;
  }
  .Select {
    border: none !important;
  }
  .react-datepicker-wrapper {
    width: 100%;
    .react-datepicker__input-container {
      width: 100%;
    }
  }
  .progress {
    background-color: rgba($color: $fontDarkBlue, $alpha: 0.2);
    height: 4px;
    border-radius: 0px;
    .progress-bar {
      background-color: $green;
    }
  }
  form {
    p {
      font-weight: 300;
      font-size: 16px;
      color: $fontDarkBlue;
      letter-spacing: 0;
      line-height: 24px;
      &.radio {
        font-size: 14px;
        font-weight: 400;
        color: #34495e;
        letter-spacing: 0.81px;
        margin-bottom: 0;
      }
    }
  }
  h5 {
    font-weight: 500;
    font-size: 20px;
    color: $fontDarkBlue;
    letter-spacing: 0;
  }
  label {
    font-size: 14px;
    color: $fontDarkBlue;
    letter-spacing: 0.76px;
    font-weight: bold;
  }
  .Select {
    width: 100%;
    height: 60px;
    border-radius: 3px;
    border: 1px solid #dddddd;
    background-color: transparent;
    input {
      all: unset !important;
    }
    .Select-control {
      height: 100%;
      border: 0;
      justify-content: center;
      align-items: center;
    }
    .Select-multi-value-wrapper {
      position: absolute;
      top: 5px;
    }
  }

  textarea {
    width: 100%;
    padding: 15px 15px;
    height: auto;
    min-height: 200px;
    max-height: 1000px;
    border-radius: 3px;
    border: 1px solid #dddddd;
    background: #ffffff;
  }
  select,
  input {
    &::-webkit-input-placeholder {
      opacity: 0.3;
      font-weight: 300;
      font-size: 18px;
      color: $fontDarkBlue;
      letter-spacing: 0;
      line-height: 32px;
    }
    &::-moz-placeholder {
      opacity: 0.3;
      font-weight: 300;
      font-size: 18px;
      color: $fontDarkBlue;
      letter-spacing: 0;
      line-height: 32px;
    }
    &::-ms-placeholder {
      opacity: 0.3;
      font-weight: 300;
      font-size: 18px;
      color: $fontDarkBlue;
      letter-spacing: 0;
      line-height: 32px;
    }
    &::placeholder {
      opacity: 0.3;
      font-weight: 300;
      font-size: 18px;
      color: $fontDarkBlue;
      letter-spacing: 0;
      line-height: 32px;
    }
  }
}
