@import '../../variables';

.homepage {
  .title-wrapper {
    max-width: 900px;
    padding-top: 50px;

    @include media-breakpoint-down(xs) {
      padding-top: 0px;
    }
  }
  .page-header {
    height: 550px;
    @include media-breakpoint-up(sm) {
      .title-wrapper {
        h1 {
          margin-top: 40px;
        }
      }
    }
  }
  .allCoursesButton {
    opacity: 0.8;
    font-weight: 300;
    font-size: 18;
    color: $fontDarkBlue;
    letter-spacing: 0;
    line-height: 32px;

    span {
      opacity: 1 !important;
    }
  }

  .page-section {
    width: 100%;
    position: relative;
    padding-top: 50px;
    margin-bottom: 100px;
    @include media-breakpoint-down(xs) {
      margin-top: 0;
      margin-bottom: 50px;
      padding-top: 10px;
    }
    &.second-why {
      @include media-breakpoint-down(xs) {
        padding-top: 0px;
      }
    }

    .section-title {
      font-size: 1.95rem;
      text-align: center;
      max-width: 700px;
      float: none;
      font-weight: 900;
      margin: 0px auto 35px auto;
      margin-bottom: 35px;
      line-height: 1.2;
    }
  }

  .course-buckets {
    margin-top: 35px;

    .button-container {
      display: flex;
      padding-top: 35px;
      justify-content: center;

      button {
        &:hover {
          background-color: #41b969 !important;
        }
      }
    }
  }

  .q-and-a {
    overflow: hidden;
    @include media-breakpoint-up(sm) {
      margin-top: 35px;
    }
    @include media-breakpoint-down(sm) {
      .row.spacer-100 {
        margin-top: 0;
      }
    }
    padding-bottom: 100px;

    img {
      height: 120%;
      min-width: 100%;
      left: 50%;
      transform: translateX(-50%);
    }

    .button-container {
      display: flex;
      padding-top: 35px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @include media-breakpoint-up(sm) {
        margin-bottom: 100px;
      }
      button {
        &:hover {
          background-color: #41b969 !important;
        }
      }
    }
  }
  .popular-courses {
    margin-top: -100px;
    padding: 10px 0;
    background-color: #3466f7;
    color: $fontWhite;
    strong {
      cursor: pointer;
    }
    .collapse {
      margin-top: 10px;
      background-color: #264ebd;
      flex-wrap: wrap;
      flex-direction: row;
      margin: 0 auto;
      padding: 0 10%;
      justify-content: left;
    }
    .show {
      columns: 3 200px;
      column-gap: 1rem;
    }
    .course-group {
      display: inline-block;
      width: 100%;

      h2 {
        text-align: left;
        a {
          color: $fontWhite;
        }
      }
      .popular-heading {
        font-size: 14px;
        font-weight: bold;
        margin-top: 20px;
        a {
          text-decoration: underline;
        }
      }
      .popular-child {
        font-size: 14px;
        font-weight: normal;
        margin-left: 10px;
      }
    }
    .arrow {
      border: solid $fontWhite;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 5px 5px;
      margin: 0px 0px 3px 3px;
    }
    .up {
      transform: rotate(-135deg);
      -webkit-transform: rotate(-135deg);
    }

    .down {
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
    }
  }
  .join-over {
    margin-top: 100px;
    @include media-breakpoint-down(xs) {
      margin-bottom: 0;
    }
    .section-title {
      @include media-breakpoint-down(xs) {
        text-align: center !important;
      }
    }

    @include media-breakpoint-down(xs) {
      text-align: center;
      margin-top: 50px;
    }
  }

  .why-study {
    margin-top: 100px;
    display: flex;
    align-items: center;

    @include media-breakpoint-down(xs) {
      text-align: center;
      margin-top: 50px;
    }

    .section-heading {
      font-weight: 900;
      font-size: 1.25rem;
      margin-bottom: 0.5rem;
      line-height: 1.2;
    }

    img {
      box-shadow: 0 5px 24px 0 rgba(160, 171, 193, 0.4);

      @include media-breakpoint-down(xs) {
        margin-top: 20px;
      }
    }
  }
}
