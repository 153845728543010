@import '../../variables.scss';

.chartered-page {
  .page-header {
    min-height: 500px;
    height: 500px;
    margin-bottom: 0px;
    @include media-breakpoint-down(xs) {
      //background-color: #238bf8;
      justify-content: flex-end;
      height: 480px;
      min-height: 480px;
    }

    img.main {
      @include media-breakpoint-down(xs) {
        height: 330px !important;
      }
    }
  }
  .title-wrapper {
    padding-top: 0px;

    @include media-breakpoint-down(xs) {
      justify-content: flex-end;
    }
  }

  .page-content {
    padding-top: 0px;
  }
}
