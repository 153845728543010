@import url("https://use.typekit.net/fuz2akd.css");
.createPasswordWrapper {
  background-color: rgba(9, 62, 95, 0.06); }
  .createPasswordWrapper .navbar {
    background-color: white; }
  .createPasswordWrapper .title {
    color: #093e5f;
    font-family: 'Museo Sans', sans-serif;
    font-size: 30px;
    font-weight: 500;
    line-height: 43px;
    text-align: center;
    margin-bottom: 30px; }
  .createPasswordWrapper select::-webkit-input-placeholder,
  .createPasswordWrapper input::-webkit-input-placeholder {
    opacity: 0.3;
    font-weight: 300;
    font-size: 18px;
    color: #093E5F;
    letter-spacing: 0;
    line-height: 32px; }
  .createPasswordWrapper select::-moz-placeholder,
  .createPasswordWrapper input::-moz-placeholder {
    opacity: 0.3;
    font-weight: 300;
    font-size: 18px;
    color: #093E5F;
    letter-spacing: 0;
    line-height: 32px; }
  .createPasswordWrapper select::-ms-placeholder,
  .createPasswordWrapper input::-ms-placeholder {
    opacity: 0.3;
    font-weight: 300;
    font-size: 18px;
    color: #093E5F;
    letter-spacing: 0;
    line-height: 32px; }
  .createPasswordWrapper select::placeholder,
  .createPasswordWrapper input::placeholder {
    opacity: 0.3;
    font-weight: 300;
    font-size: 18px;
    color: #093E5F;
    letter-spacing: 0;
    line-height: 32px; }
  .createPasswordWrapper input {
    width: 100%;
    height: 60px;
    padding: 15px 15px;
    border-radius: 3px;
    border: 1px solid #dddddd;
    background: #ffffff; }
  .createPasswordWrapper label {
    color: #093e5f;
    font-family: 'Museo Sans', sans-serif;
    font-size: 16px;
    letter-spacing: 0.76px;
    font-weight: 500;
    margin: 0; }
  .createPasswordWrapper form {
    background-color: white; }
