@import '../../variables.scss';

.breadcrumb {
  background: transparent !important;
  font-size: 0.8rem;
  padding-left: 0px !important;
  text-transform: uppercase;

  @include media-breakpoint-down(sm) {
    display: none !important;
  }
}
