@import '../../variables';

.testimonials-page {
  .page-header {
    height: 400px;
    .title-wrapper {
      padding-top: 50px;
      text-align: center;
      justify-content: center;
      flex-direction: row;

      h1 {
        text-align: center;
        max-width: 600px;
      }
    }
  }

  .large-blue-circle {
    height: 105%;
    min-width: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
}
