@import '../../variables.scss';

.course-page {
  .similar-block {
    @media screen and (max-width: 991px) {
      margin-bottom: 50px;
    }
    .heading {
      font-weight: 900;
      margin-bottom: 15px;
      font-size: 1.1rem;
      padding-left: 15px;
    }
    .course-category {
      color: #007bff;
      text-transform: capitalize;
      font-size: 16px;
      &:hover {
        background-color: white;
      }
    }
    span {
      color: #007bff;
    }
    .title {
      font-weight: bold;
      font-size: 16px;
    }
    .subtitle {
      font-weight: 100;
      font-size: 14px;
    }
    .button-container {
      max-width: 180px;
      width: 100%;
      display: flex;
      align-items: center;

      @include media-breakpoint-down(sm) {
        max-width: none;
        padding: 0px;
      }
      button {
        background: #f1f4f6;
        box-shadow: 0 0px 14px 0 rgba(77, 199, 117, 0.2);
        color: $fontDarkBlue;
        border: 0px;
        font-size: 0.7rem;
        width: 100%;
        text-transform: uppercase;
        height: 30px;
        i {
          margin-left: 8px;
        }
      }
    }
    hr {
      margin-bottom: 0;
      margin-top: 0;
    }
    a {
      display: block;
      padding: 1rem 15px;
      text-decoration: none;
      color: #093e5f;
      &:hover {
        background-color: rgba($color: #f1f4f6, $alpha: 1);
      }
    }
  }
  .page-header {
    //min-height: 500px;
    height: 500px;
    margin-bottom: 0;
    @include media-breakpoint-down(xs) {
      //background-color: #238bf8;
      justify-content: flex-end;
      height: 350px;
      margin-top: 20px;
      //min-height: 480px;
    }

    img.main {
      @include media-breakpoint-down(xs) {
        height: 330px !important;
      }
    }
  }
  .title-wrapper {
    padding-top: 20px;

    @include media-breakpoint-down(xs) {
      justify-content: flex-end;
    }

    .unit-label {
      background: #238bf8;
      color: white;
      font-size: 1em;
      display: inline-block;
      width: 210px;
      text-align: center;
      border-radius: 3px;
      flex: 1;
      padding: 5px 10px;
      text-transform: uppercase;
      font-weight: 600;
    }

    .course-link {
      font-weight: 600;
      margin-top: 30px;
      i {
        margin-right: 10px;
      }
    }

    h1 {
      font-size: 2.2rem;
      max-width: 800px;

      @include media-breakpoint-down(xs) {
        font-size: 1.7rem;
        line-height: 32px;
      }
    }

    h2 {
      margin-top: 30px;
      max-width: 800px;

      @include media-breakpoint-down(xs) {
        margin-top: 20px;
      }
    }

    @include media-breakpoint-down(xs) {
      padding-top: 0px;
    }
  }

  .course-main-content {
    .content-block {
      margin-bottom: 45px;

      .course-link {
        font-weight: 600;
        margin-top: 30px;
        i {
          margin-right: 10px;
        }
      }

      .title {
        font-weight: 900;
        font-size: 1.7rem;
        margin-bottom: 25px;

        @include media-breakpoint-down(xs) {
          font-size: 1.2rem;
          line-height: 28px;
        }
      }

      p,
      li {
        @include media-breakpoint-down(xs) {
          font-size: 0.9rem;
        }
      }
    }
  }

  .course-image {
    background: white;
    padding:  0 0 15px 0;
    img {
      position: relative;
      margin-top: 0px;
      max-width: 140px !important;
      vertical-align: baseline !important;
    }
  }

  .download-block {
    text-align: center;
    width: 100%;
    font-weight: 700;
    font-size: 0.9rem;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .course-side {
    background: #f1f4f6;
    padding-top: 20px;
    margin-bottom: 40px;

    @include media-breakpoint-down(xs) {
      margin-top: 20px;
    }

    .block {
      padding: 20px;

      ul {
        padding-left: 15px;
        margin-bottom: 0px;
        padding-bottom: 0px;

        li {
          font-size: 0.9rem;
        }
      }

      .title {
        font-weight: 900;
        margin-bottom: 15px;
        font-size: 1.1rem;
      }

      p {
        font-size: 0.9rem;
      }
      .inner-block {
        margin-bottom: 15px;

        .inner-title {
          display: inline-block;
          font-weight: 700;
          padding-right: 10px;
        }

        .data {
          display: inline-block;
        }
      }

      .highlight-price {
        font-weight: 600;
        display: block;
        width: 100%;
        color: #037bff;
      }
    }

    .apply-button {
      @include media-breakpoint-down(xs) {
        position: fixed;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
        z-index: 1000;
        background: white;
        bottom: 0px;
        left: 0px;
        width: 100%;
      }
    }

    .pdf-button {
      cursor: pointer;
      a {
        border: 1px solid $darkBlue;
        display: block;
        text-align: center;
        border-radius: 3px;
        padding: 8px 10px;
        color: $darkBlue;
        font-weight: 700;
        font-size: 0.9em;

        i {
          margin-right: 10px;
        }
      }
    }

    .contact {
      text-align: center;
      font-size: 0.9rem !important;
      opacity: 0.6;

      strong {
        display: block;
        width: 100%;
      }
    }
  }
}

.courseWrapper {
  .row {
    &.spacer-50,
    .spacer-75,
    .spacer-100 {
      .col {
        ul {
          padding-left: 20px !important;
        }
      }
    }
  }
  .bread-crumb {
    font-size: 14px;
    font-weight: 100;
    color: white;
    letter-spacing: 0;
    background-color: transparent;
    padding: 0px;
    @media screen and (min-width: 493px) {
      color: #093e5f;
    }
  }
  .heading,
  .sub-heading {
    @media screen and (max-width: 492px) {
      color: white;
    }
  }
  ul {
    padding-left: 20px;
  }
  .cant-find {
    opacity: 0.47;
    font-weight: 300;
    font-size: 14px;
    color: #093e5f;
    letter-spacing: 0;
    line-height: 21px;
  }
  h4 {
    margin-bottom: 40px;
  }
  .course-durations {
    padding: 40px;
    @media screen and (max-width: 492px) {
      padding: 20px 0;
    }
    .prices {
      p {
        margin-bottom: 30px !important;
      }
    }
    p {
      margin-bottom: 0;
      float: right;
      padding-right: 0;
      width: 100%;
    }
    span {
      h5 {
        margin-bottom: 0;
      }
    }
  }
}
