.courseBucket {
  padding: 15px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 250px;
  border: 1px solid #c3dff9 !important;
  box-shadow: 0 4px 17px 0 rgba(160, 171, 193, 0.35);
  transition: all 0.3s ease;
  margin-bottom: 10px;

  &:hover {
    box-shadow: 0 4px 17px 0 rgba(160, 171, 193, 0.65);
    transform: translateY(-10px);
  }
}

.bucketImage {
  width: auto;
  max-height: 100px;
  max-width: 100%;
  margin-bottom: 20px;
}

.bucketTitle {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}
