@import '../../variables';

.privacy-page {
  .page-header {
    height: 300px;
    .title-wrapper {
      padding-top: 50px;
      text-align: center;
      justify-content: center;
      flex-direction: row;

      h1 {
        text-align: center;
        max-width: 600px;
      }
    }
  }
}
