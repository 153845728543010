@import '../../variables.scss';

.footer {
  background-color: $footerBlue;
  color: $fontWhite;
  font-size: 16px;
  letter-spacing: 0;
  padding-top: 80px;
  height: auto;
  @media screen and (min-width: 992px) {
    height: 310px;
  }

  .link-wrapper {
    display: flex;
    justify-content: center;
  }

  .social-icons {
    margin-top: 10px;
    padding-left: 15px;
    justify-content: center;
    @media screen and (min-width: 768px) {
      margin-top: 0;
      justify-content: inherit;
    }

    div {
      padding: 5px 15px 5px 0;
    }
  }

  position: relative;
  bottom: 0px;
  width: 100%;
  ul {
    list-style-type: none;
    padding: 0;
    margin-bottom: 0px;
    text-align: center;
    padding-left: 0px;
    @media screen and (min-width: 992px) {
      text-align: left;
      width: 50%;
    }
    li {
      width: 100%;

      a {
        color: $fontWhite;
        text-decoration: none;
      }
      display: inline-flex;
      margin-left: 0px;
      margin-right: 10px;
      @media screen and (min-width: 992px) {
        display: list-item;
        margin: inherit 0px;
      }
    }
  }

  i {
    color: $fontWhite;
    opacity: 0.7;
    font-size: 28px;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    &:hover {
      opacity: 1;
      cursor: pointer;
    }
  }
  .footer-location {
    text-align: left;
    margin-top: 25px;

    p {
      font-size: 13px !important;
    }
    @media screen and (min-width: 992px) {
      margin-top: 0px;
      text-align: left;
      font-size: 1rem;
    }
  }
}
