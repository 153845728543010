@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/_breakpoints';

$footerBlue: #031026;
$darkBlue: #34495e;
$green: #4dc775;
$borderBlue: #358ed7;
$fontDarkBlue: #093e5f;
$fontWhite: #ffffff;
$iconBlack: #383b41;
$breadcrumb-divider: quote('>');

@import '~bootstrap/scss/variables';
