@import '../../variables.scss';

.news-page {
  overflow-x: hidden;

  .main-image {
    left: 50%;
    transform: translateX(-50%);

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  h3 {
    font-size: 35px;
    line-height: 38px;
    margin-top: 35px;
  }

  .page-header {
    height: 360px;
    margin-bottom: 60px;

    @include media-breakpoint-down(sm) {
      padding-bottom: 50px;
      height: 350px;
      margin-bottom: 30px;
    }
    .title-wrapper {
      padding-top: 50px;
      text-align: center;
      justify-content: center;
      flex-direction: row;

      @include media-breakpoint-down(sm) {
        padding-top: 0px;
      }

      h1 {
        text-align: center;

        @include media-breakpoint-down(sm) {
          font-size: 2.5rem;
        }
      }

      h2 {
        color: white;
      }
    }
  }

  span {
    font-weight: 900;
    font-size: 12px;
    color: #093e5f;
    letter-spacing: 0.2px;
    line-height: 16px;
  }
  .categoryTag {
    padding: 0 5px;
    div {
      background-color: rgba($color: $fontDarkBlue, $alpha: 0.06);
      padding: 0 10px;
      border-radius: 10px;
    }
  }

  input {
    width: calc(100% - 90px);
    height: 60px;
    padding: 15px 15px;
    border-radius: 3px;
    border: 1px solid #dddddd;
    background: #ffffff;
  }

  form {
    div {
      display: inline;
    }
    button {
      position: absolute;
      right: 0px;
    }
  }

  a {
    text-decoration: none;
  }
}
