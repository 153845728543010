@import '../../variables.scss';

.courses-page {
  .w-70 {
    width: 70%;
  }

  .w-md-70 {
    @media screen and (min-width: 992px) {
      width: 70% !important;
    }
  }

  .q-and-a {
    // margin-top: 35px;
    padding-bottom: 100px;
    position: relative;
    //overflow: hidden;
    width: 100%;
    padding-top: 50px;
    margin-bottom: 100px;

    img {
      height: 120%;
      min-width: 100%;
      left: 50%;
      transform: translateX(-50%);
    }

    .button-container {
      display: flex;
      padding-top: 35px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 100px;

      button {
        &:hover {
          background-color: #41b969 !important;
        }
      }
    }
  }

  .chatbot {
    margin-top: 2rem;
  }

  .Select-placeholder {
    color: #093e5f !important;
  }

  .page-header {
    //height: 600px;
    height: auto;
    margin-bottom: 50px;
    margin-top: 100px;
  }

  .title-wrapper {
    padding-top: 0px;

    h1 {
      margin-left: auto;
      margin-right: auto;
      max-width: 600px;
      text-align: center;
    }

    h2 {
      margin-top: 30px;
      text-align: center;
    }

    @include media-breakpoint-down(xs) {
      padding-top: 0px;
    }
  }

  .course-selections {
    margin-top: 50px;
    margin-bottom: 0px;
  }

  .course-list {
    .course {
      flex: 1;
      width: 100%;
      min-height: 190px;
      overflow: hidden;
      padding: 8px 8px 12px 8px;
      display: flex;
      justify-content: center;
      margin-bottom: 25px;
      box-shadow: 0 4px 17px 0 rgba($color: #a0abc1, $alpha: 0.4);
      background-color: rgba($color: #f1f4f6, $alpha: 0.06);

      &:hover {
        box-shadow: 0 4px 17px 0 rgba($color: #a0abc1, $alpha: 0.7);
        cursor: pointer;
      }

      @include media-breakpoint-down(sm) {
        flex-direction: column;
        align-items: flex-start;
      }

      .course-title {
        font-weight: 900;
        line-height: 26px;
        margin-bottom: 10px;
        font-size: 1.15rem !important;

        @include media-breakpoint-down(sm) {
          font-size: 1rem !important;
          line-height: 22px;
        }
      }

      .course-image {
        padding: 5px 25px 5px 25px;
        background: white;
        max-width: 150px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        @include media-breakpoint-down(sm) {
          max-width: none;
        }

        img {
          width: 100%;
          max-width: 150px;
          max-height: 100px;
          object-fit: contain;
        }
      }

      .course-detail {
        padding: 15px;
        flex: 1;

        @include media-breakpoint-down(sm) {
          padding: 10px;
        }

        p {
          font-size: 0.85rem;
          margin-bottom: 0px;

          span {
            font-weight: 600;
          }
        }
      }

      .button-container {
        padding: 0px 15px;
        max-width: 180px;
        width: 100%;
        display: flex;
        align-items: center;

        @include media-breakpoint-down(sm) {
          max-width: none;
          padding: 0px;
        }

        button {
          background: white;
          //box-shadow: 0 0px 14px 0 rgba(77, 199, 117, 0.2);
          color: $fontDarkBlue;
          border: 0px;
          font-size: 0.7rem;
          width: 100%;
          text-transform: uppercase;

          i {
            margin-left: 8px;
          }
        }
      }

      .CourseDescription {
        overflow: hidden;
      }
    }
  }
}

.coursesWrapper {
  ul {
    padding-left: 0 !important;
  }

  .Select {
    margin-bottom: 10px;
  }

  .Select-placeholder,
  .Select-value-label,
  .Select-menu,
  .Select-input input {
    font-size: 16px;
  }

  .course {
    height: auto;
    overflow: hidden;
    background-color: rgba($color: #f1f4f6, $alpha: 0.06);

    &:hover {
      box-shadow: 0 4px 17px 0 rgba($color: #a0abc1, $alpha: 0.7);
      cursor: pointer;
    }

    .CourseDescription {
      overflow: hidden;
    }
  }
}