.page-content {
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: left;

  h3.page-summary {
    text-align: left;
    font-size: 1.3rem;
    line-height: 1.5;
  }
}
