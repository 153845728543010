@import '../../variables.scss';

.contact-page {
  .react-select-contact {
    text-align: left;
    .Select-multi-value-wrapper {
      height: 100%;
    }
    .Select-input {
      height: 50px;
      padding-top: 0;
      input {
        height: 50px;
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 0;
        border: none !important;
      }
    }
  }
  .page-header {
    height: 400px;
    @include media-breakpoint-down(xs) {
      height: 300px;
    }
  }
  .title-wrapper {
    padding-top: 0px;

    h1 {
      font-size: 2.2rem;
      text-align: center;

      @include media-breakpoint-down(xs) {
        font-size: 1.7rem;
      }
    }

    h2 {
      margin-top: 30px;
      text-align: center;
    }

    @include media-breakpoint-down(xs) {
      padding-top: 0px;
    }
  }

  input {
    border: 1px solid rgba(9, 62, 95, 0.4) !important;
  }
  textarea {
    border: 1px solid rgba(9, 62, 95, 0.4) !important;
  }
  .Select-control {
    border: 1px solid rgba(9, 62, 95, 0.4) !important;
  }
  .Select {
    border: none !important;
  }
  .hero {
    h3 {
      @media screen and (max-width: 991px) {
        font-size: 37px;
        line-height: 45px;
      }
      @media screen and (max-width: 767px) {
        font-size: 28px;
        line-height: 35px;
      }
    }
  }
  form {
    width: 100%;
    text-align: center;
  }
  input {
    width: 100%;
    height: 60px;
    padding: 15px 15px;
    border-radius: 3px;
    border: 1px solid #dddddd;
    background: #ffffff;
  }
  textarea {
    width: 100%;
    padding: 15px 15px;
    height: auto;
    min-height: 200px;
    max-height: 1000px;
    border-radius: 3px;
    border: 1px solid #dddddd;
    background: #ffffff;
  }
  select {
    width: 100%;
    height: 60px;
    font-size: 14px;
    padding: 15px 15px;
    border-radius: 3px;
    text-align-last: center;
    border: 1px solid #dddddd;
    background: #ffffff;
    &.contactReason {
      background-color: white !important;
      border: 1px solid $green;
      color: $green;
      margin-bottom: 40px;
    }
  }
  select,
  input, textarea {
    &::-webkit-input-placeholder {
      opacity: .8;
      font-weight: 300;
      font-size: 18px;
      color: $fontDarkBlue;
      letter-spacing: 0;
      line-height: 32px;
    }
    &::-moz-placeholder {
      opacity: .8;
      font-weight: 300;
      font-size: 18px;
      color: $fontDarkBlue;
      letter-spacing: 0;
      line-height: 32px;
    }
    &::-ms-placeholder {
      opacity: .8;
      font-weight: 300;
      font-size: 18px;
      color: $fontDarkBlue;
      letter-spacing: 0;
      line-height: 32px;
    }
    &::placeholder {
      opacity: .8;
      font-weight: 300;
      font-size: 18px;
      color: $fontDarkBlue;
      letter-spacing: 0;
      line-height: 32px;
    }
  }
}
