@import './variables';
@import '../node_modules/@fortawesome/fontawesome-free/css/all.css';
@import '~bootstrap/scss/bootstrap.scss';
body,
html {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  height: auto;
  overflow-x: hidden;
  font-family: 'museo-sans', sans-serif;
  font-weight: 300;
  font-size: 18px;
  color: $fontDarkBlue;

  &.modal-open {
    overflow: hidden;
  }
}

.application-form-page {
  .apply-wrapper {
    .wrapper {
      z-index: 100;
    }
  }
}

.modal-body {
  max-width: 100%;
}

@media (max-width: 575px) {
  #intercom-container,
  #topcontrol {
    display: none;
  }
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url('/images/icons/caret-down-solid.svg') right / 30px no-repeat !important;
}

.clearfix {
  clear: both;
  float: none;
}

.fixed-top {
  z-index: 9999 !important;
}

.Select-arrow {
  border-color: black transparent transparent;
}
.Select-value {
  padding-top: 8px;
}
.Select-input {
  padding-top: 6px;
}
.Select-placeholder {
  color: black;
  padding-top: 8px;
}
.Select-control {
  height: 50px;
}

.bm-item.nav-white.text-white.navbar-nav {
  &:focus {
    outline: none;
  }
}

.autoSuggestWrapper {
  .react-autosuggest__suggestion--highlighted {
    background-color: #e4eaee !important;
  }
}
#topcontrol {
  bottom: 25px !important;
  right: 90px !important;
  z-index: 99999;
}
@media screen and (min-width: 768px) {
  #topcontrol {
    bottom: 15px !important;
    right: 90px !important;
    width: 76px;
    height: 60px;
    img {
      float: right;
      width: 52px;
      height: 52px;
    }
  }
}

.text-darkBlue {
  color: $fontDarkBlue !important;
}

h3 {
  font-weight: 900;
  font-size: 48px;
  color: $fontDarkBlue;
  letter-spacing: -0.6px;
  line-height: 64px;
}

h4 {
  font-weight: 900;
  font-size: 36px;
  color: $fontDarkBlue;
  letter-spacing: 0;
  text-align: center;
  line-height: 40px;
}

.F1F4F6 {
  background-color: #f1f4f6;
}

.p-spacer-50 {
  padding-top: 150px;
}
.p-spacer-100 {
  padding-top: 100px;
}
.p-spacer-150 {
  padding-top: 150px;
}
.p-spacer-200 {
  padding-top: 200px;
}
.pb-spacer-100 {
  padding-bottom: 100px;
}
.pb-spacer-200 {
  padding-bottom: 200px;
}
.spacer-15 {
  margin-top: 15px;
}
.spacer-25 {
  margin-top: 25px;
}
.spacer-50 {
  margin-top: 50px;
}
.spacer-75 {
  margin-top: 75px;
}
.spacer-100 {
  margin-top: 100px;
}
.spacer-150 {
  margin-top: 150px;
}
.spacer-200 {
  margin-top: 200px;
}
.spacer-250 {
  margin-top: 250px;
}
.spacer-275 {
  margin-top: 275px;
}
.spacer-450 {
  margin-top: 450px;
}
.spacer-bottom-50 {
  margin-bottom: 50px;
}
.spacer-bottom-100 {
  margin-bottom: 100px;
}
.spacer-bottom-150 {
  margin-bottom: 150px;
}
.spacer-bottom-200 {
  margin-bottom: 200px;
}
.spacer-bottom-250 {
  margin-bottom: 250px;
}
.spacer-bottom-300 {
  margin-bottom: 300px;
}
.error {
  color: red !important;
}
.error-input {
  color: red !important;
  border: 1px solid red !important;
}

a.nounderline {
  text-decoration: none !important;
  color: inherit;
  &:hover {
    text-decoration: none !important;
  }
}

.row-border {
  border-bottom: 1px solid rgba($color: #c1c7d0, $alpha: 0.25);
}

.card-body {
  height: 300px;
}

button {
  &:hover {
    cursor: pointer;
  }
  &:focus {
    outline: none;
  }
}

input {
  &:focus {
    outline: none;
  }
}

.homepage {
  &.topRight {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
  }
  &.left {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    margin: 0 0 0 -350px !important;
  }
  &.right {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    margin: 0 -350px 0 0 !important;
  }
  &.blue-80 {
    height: 700px;
    width: 700px;
    margin-right: -200px;
    margin-top: -200px;
    background-color: rgba($color: #1c7af5, $alpha: 0.8);
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    transform-origin: 45% 55%;
    z-index: -10;
  }
  &.blue-50 {
    height: 700px;
    width: 700px;
    margin-right: -180px;
    margin-top: -180px;
    background-color: rgba($color: #1c7af5, $alpha: 0.5);
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    transform-origin: 45% 55%;
  }
  &.blue-30 {
    height: 700px;
    width: 700px;
    margin-right: -190px;
    margin-top: -190px;
    background-color: rgba($color: #1c7af5, $alpha: 0.3);
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    transform-origin: 45% 55%;
  }
  &.blue-15 {
    height: 700px;
    width: 700px;
    margin-right: -220px;
    margin-top: -220px;
    background-color: rgba($color: #1c7af5, $alpha: 0.15);
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    transform-origin: 45% 55%;
  }
  &.twoThirds {
    height: 500px !important;
    width: 500px !important;
    margin-right: -125px;
    margin-top: -135px;
  }
  &.topHalf {
    top: 50%;
  }
  &.topThird {
    top: 20%;
    height: 600px !important;
    width: 600px !important;
    margin-right: -125px;
    margin-top: -125px;
  }
  &.clockwise-10 {
    webkitanimation: clockwise 10s linear infinite;
    mozanimation: clockwise 10s linear infinite;
    msanimation: clockwise 10s linear infinite;
    oanimation: clockwise 10s linear infinite;
    animation: clockwise 10s linear infinite;
  }
  &.clockwise-12 {
    webkitanimation: clockwise 12s reverse infinite;
    mozanimation: clockwise 12s reverse infinite;
    msanimation: clockwise 12s reverse infinite;
    oanimation: clockwise 12s reverse infinite;
    animation: clockwise 12s reverse infinite;
  }
  &.clockwise-14 {
    webkitanimation: clockwise 14s alternate infinite;
    mozanimation: clockwise 14s alternate infinite;
    msanimation: clockwise 14s alternate infinite;
    oanimation: clockwise 14s alternate infinite;
    animation: clockwise 14s alternate infinite;
  }
  &.clockwise-8 {
    webkitanimation: clockwise 8s linear infinite;
    mozanimation: clockwise 8s linear infinite;
    msanimation: clockwise 8s linear infinite;
    oanimation: clockwise 8s linear infinite;
    animation: clockwise 8s linear infinite;
  }
}

@-webkit-keyframes clockwise /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(1turn);
    -moz-transform: rotate(1turn);
    -ms-transform: rotate(1turn);
    -o-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
@keyframes clockwise {
  from {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(1turn);
    -moz-transform: rotate(1turn);
    -ms-transform: rotate(1turn);
    -o-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@-webkit-keyframes move-up {
  0% {
    opacity: 0;
    top: 30vh;
    width: 0px;
    height: 0px;
    margin-right: -7.5px;
  }
  45% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    top: -10vh;
    width: 350px;
    height: 350px;
    margin-right: -175px;
  }
}

@-webkit-keyframes move-up-right {
  0% {
    opacity: 0;
    top: 30vh;
    width: 0px;
    height: 0px;
    margin-right: 300px;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    top: -10vh;
    width: 350px;
    height: 350px;
    margin-right: -300px;
  }
}

@-webkit-keyframes move-up-left {
  0% {
    opacity: 0;
    top: 30vh;
    width: 0;
    height: 0;
    margin-right: -200px;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    top: -10vh;
    width: 350px;
    height: 350px;
    margin-right: 200px;
  }
}

@keyframes move-up-right {
  0% {
    opacity: 0;
    top: 30vh;
    width: 0px;
    height: 0px;
    margin-right: 300px;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    top: -10vh;
    width: 350px;
    height: 350px;
    margin-right: -300px;
  }
}

@keyframes move-up-left {
  0% {
    opacity: 0;
    top: 30vh;
    width: 0;
    height: 0;
    margin-right: -200px;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    top: -10vh;
    width: 350px;
    height: 350px;
    margin-right: 200px;
  }
}

@keyframes move-up {
  0% {
    opacity: 0;
    top: 30vh;
    width: 0px;
    height: 0px;
    margin-right: -7.5px;
  }
  45% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    top: -10vh;
    width: 350px;
    height: 350px;
    margin-right: -175px;
  }
}
