@import url("https://use.typekit.net/fuz2akd.css");
.navbar {
  -webkit-transition: background .5s ease-out;
  -moz-transition: background .5s ease-out;
  -o-transition: background .5s ease-out;
  transition: background .5s ease-out;
  background-color: rgba(255, 255, 255, 0);
  padding: 9px 18px; }
  .navbar .nav-number {
    display: none; }
    @media screen and (min-width: 1200px) {
      .navbar .nav-number {
        display: inline-block; } }
  .navbar .navBtn {
    display: none; }
  .navbar .loginText {
    display: inline; }
  @media screen and (min-width: 992px) {
    .navbar {
      padding: 9px 70px;
      color: white; }
      .navbar .navBtn {
        display: inline; }
      .navbar .loginText {
        display: none; } }
  .navbar.nav-solid {
    -webkit-transition: background .5s ease-out;
    -moz-transition: background .5s ease-out;
    -o-transition: background .5s ease-out;
    transition: background .5s ease-out;
    background-color: white;
    color: #093E5F !important; }
    .navbar.nav-solid .navBtn {
      display: none; }
    .navbar.nav-solid .loginText {
      display: inline; }
    @media screen and (min-width: 992px) {
      .navbar.nav-solid {
        color: white; }
        .navbar.nav-solid .navBtn {
          display: inline; }
          .navbar.nav-solid .navBtn button {
            background-color: rgba(9, 62, 95, 0.09) !important; }
        .navbar.nav-solid .loginText {
          display: none; } }
  .navbar .nav-item {
    margin: auto 8px; }
  .navbar a {
    text-decoration: none;
    color: #093E5F;
    font-weight: bold;
    font-size: 15px;
    letter-spacing: 0; }
    .navbar a:hover {
      text-decoration: none; }
  .navbar .navbar-collapse.show, .navbar .navbar-collapse.collapsing {
    background-color: white;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    padding-bottom: 5px; }
  .navbar .navbar-toggler {
    width: 45px;
    height: 45px;
    background: url("/images/icons/hamburger_dark.svg") no-repeat;
    background-size: cover; }
    .navbar .navbar-toggler .navbar-toggler-icon {
      display: none; }
