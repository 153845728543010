@import '../../../variables.scss';

.navbar {
  -webkit-transition: background 0.5s ease-out;
  -moz-transition: background 0.5s ease-out;
  -o-transition: background 0.5s ease-out;
  transition: background 0.5s ease-out;
  background-color: rgba($color: white, $alpha: 0);
  padding: 9px 70px !important;
  align-items: baseline !important;
  .courses-dropdown {
    &:hover {
      .courses-dropdown-menu {
        margin-top: 5px;
        padding-top: 15px;
        padding-bottom: 15px;
        display: inline-block;
        z-index: 999;
        background-color: white;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
        width: 300px;
        margin-left: -56px;
        height: auto;
        position: absolute;
        -webkit-box-shadow: 0px 3px 41px 2px rgba(0,0,0,0.13);
        -moz-box-shadow: 0px 3px 41px 2px rgba(0,0,0,0.13);
        box-shadow: 0px 3px 41px 2px rgba(0,0,0,0.13);
        .square {
          display: block;
          position: absolute;
          width: 14px;
          height: 14px;
          background-color: #fff;
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
          top: -8px;
          left: 101px;
        }
        a {
          padding: 4px 35px;
        }
      }
    }
    .courses-dropdown-menu {
      display: none;
    }
  }
  .nav-number {
    display: none;
    a {
      font-size: 20px;
    }
    @media screen and (min-width: 1200px) {
      display: inline-block;
      font-size: 30px !important;
    }
  }
  .navBtn {
    display: none;
  }
  .loginText {
    display: inline;
  }
  @media screen and (min-width: 992px) {
    padding: 9px 70px;
    color: white;
    .navBtn {
      display: list-item;
    }
    .loginText {
      display: none;
    }
  }
  &.nav-solid {
    -webkit-transition: background 0.5s ease-out;
    -moz-transition: background 0.5s ease-out;
    -o-transition: background 0.5s ease-out;
    transition: background 0.5s ease-out;
    background-color: rgba($color: white, $alpha: 1);
    color: $fontDarkBlue !important;
    .navBtn {
      display: none;
    }
    .loginText {
      display: inline;
    }
    @media screen and (min-width: 992px) {
      color: white;
      .navBtn {
        display: inline;
        button {
          background-color: rgba(
            $color: $fontDarkBlue,
            $alpha: 0.09
          ) !important;
        }
      }
      .loginText {
        display: none;
      }
    }
  }
  .nav-item {
    margin: 0 8px auto 8px !important;
    color: $fontDarkBlue;
  }
  a {
    text-decoration: none;
    color: $fontDarkBlue;
    font-weight: bold;
    font-size: 15px;
    letter-spacing: 0;
    &:hover {
      text-decoration: none;
    }
  }

  .navbar-collapse.show,
  .navbar-collapse.collapsing {
    background-color: white;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    padding-bottom: 5px;
  }
  .navbar-toggler {
    width: 45px;
    height: 45px;
    background: url('/images/icons/hamburger.svg') no-repeat;
    background-size: cover;
    &.nav-solid {
      background: url('/images/icons/hamburger_dark.svg') no-repeat;
      background-size: cover;
    }
    .navbar-toggler-icon {
      display: none;
    }
  }
}
