@import '../../variables';

.about-page {
  .logos {
    img {
      width: 50%;
      height: auto;
    }
  }
  p {
    font-weight: 300 !important;
  }
  .page-header {
    height: auto;
    @include media-breakpoint-down(xs) {
      img {
        height: 220px !important;
      }
    }
    .title-wrapper {
      padding-top: 50px;
      text-align: center;
      justify-content: center;
      flex-direction: row;

      h1 {
        text-align: center;
        max-width: 600px;
      }
    }
  }
}
