@import '../../variables.scss';

.apprentice-page {
  .page-header {
    min-height: 550px;
    height: 550px;
    margin-bottom: 0px;
    @include media-breakpoint-down(xs) {
      //background-color: #238bf8;
      //justify-content: flex-end;
      height: 480px;
      min-height: 480px;
    }

    h2 {
      margin-bottom: 30px;
    }

    img.main {
      @include media-breakpoint-down(xs) {
        height: 260px !important;
      }
    }
  }
  .title-wrapper {
    padding-top: 0px;

    @include media-breakpoint-down(xs) {
      justify-content: flex-end;
    }
  }

  .page-content {
    padding-top: 0px;

    .course-link {
      margin-bottom: 10px;
      width: 100%;
      display: flex;
      align-items: center;
      font-weight: 700;
      line-height: 18px;
      @include media-breakpoint-down(xs) {
        margin-bottom: 15px;
        font-size: 0.9rem;
      }
      span {
        background: #238bf8;
        color: white;
        font-weight: 600;
        font-size: 1rem;
        padding: 5px 10px;
        border-radius: 3px;
        margin-right: 10px;

        @include media-breakpoint-down(xs) {
          font-size: 0.9rem;
        }

        &:hover {
          text-decoration: none !important;
        }
      }

      &:hover {
        span {
          text-decoration: none !important;
        }
      }
    }

    @include media-breakpoint-down(xs) {
      padding-top: 50px;
    }
  }
}
