@import '../../variables';

.fees-page {
  .page-header {
    height: 450px;
    .title-wrapper {
      padding-top: 50px;
      text-align: center;
      justify-content: center;
      flex-direction: row;

      h1 {
        text-align: center;
        max-width: 600px;
      }
    }
  }

  ul {
    li {
      margin-bottom: 10px;
    }
  }
}
