.page-block {
  padding-bottom: 10px;

  .header-4 {
	font-weight: 900;
    font-size: 1.1rem;
    text-align: left;
	color: #093e5f;
	letter-spacing: 0;
  }

  h4 {
    font-size: 1.1rem;
    text-align: left;
  }
}
