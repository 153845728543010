.page-wrapper {
  min-height: calc(100vh - 310px);
  //.bm-menu-wrap {
  //  -webkit-transform: unset !important;
  //  -moz-transform: unset !important;
  //  -ms-transform: unset !important;
  //  -o-transform: unset !important;
  //  transform: unset !important;
  //  opacity: 0;
  //}
  .promo-banner {
    .cross {
      position: absolute;
      top: 8px;
      right: 10px;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .promo-banner > .col-12 {
    //&:before {
    //  content: "x";
    //  font-size: 18px;
    //  position: absolute;
    //  right: 10px;
    //  top: 8px;
    //}
    position: fixed;
    z-index: 9999;
    background: rgba(46,204,113,1);
    border: 1px solid #2ECC71;
    border-radius: 3px;
    font-size: 15px;
    color: white;
    letter-spacing: 1.07px;
    text-align: center;
    line-height: 19px;
    width: 30vw;
    bottom: 15px;
    left: 15px;
    min-width: 350px;
    max-width: 600px;
    //margin-top: 90px;
    //margin-bottom: -170px;
    padding: 20px 40px;
    //@media screen and (min-width: 768px) {
    //  padding: 20px 100px;
    //}
    //@media screen and (min-width: 992px) {
    //  padding: 20px 180px;
    //}
  }
}

.global-message {
  position: fixed;
  top: 80px;
  left: 0;
  width: 100%;
  padding: 5px 50px 5px 5px;
  background: #ff9505;
  color: #000;
  text-align: center;
  font-size: 12px;
  z-index: 3;
  a {
    color: #fff;
  }
  span {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 14px;
    background: #fff;
    border-radius: 50%;
    cursor: pointer;
    width: 20px;
    height: 20px;
    padding: 0;
    line-height: 19px;
  }
}
