.q-a {
  .card-body {
    height: auto;
  }
  .title {
    font-weight: 900;
    font-size: 36px;
    color: #093e5f;
    letter-spacing: 0;
    text-align: center;
    line-height: 40px;
  }
  h5, .section-heading {
    font-weight: 800 !important;
  }
}
