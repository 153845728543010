.mobile-logo {
  position: fixed;
  left: 20px;
  top: 13px;
  z-index: 999;
  @media only screen and (max-width: 340px) {
    width: 200px;
    left: 5px;
    top: 18px;
  }
}
