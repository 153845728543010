.DashboardWrapper {
  background-color: rgba($color: #093e5f, $alpha: 0.06);
  height: auto;

  input {
    border: 1px solid rgba(9, 62, 95, 0.4) !important;
  }
  .Select-control {
    border: 1px solid rgba(9, 62, 95, 0.4) !important;
    input {
      border: none !important;
      height: 0px !important;
    }
  }
  .Select {
    border: none !important;
  }
  .react-datepicker-wrapper {
    width: 100%;
    .react-datepicker__input-container {
      width: 100%;
    }
  }

  .btn-dashboard {
    height: 35px;
    width: 50%;
    float: right;
    padding: 3.5px 0;
    border-radius: 5px;
    background-color: #15b4f1;
    box-shadow: 0 4px 4px 0 rgba(21, 180, 241, 0.2);
    color: #ffffff;
    font-family: 'Museo Sans', sans-serif;
    font-size: 13px;
    border: none;
  }
  .tabs {
    display: flex;
    padding-top: 20px;
    justify-content: space-evenly;
    .tab {
      text-align: center;
      opacity: 0.25;
      color: #093e5f;
      cursor: pointer;
      padding-left: 20px;
      padding-right: 20px;
      align-self: center;
      &.active {
        opacity: 1;
        border-bottom: 2px solid #2199e8;
      }
    }
  }

  .navbar {
    background-color: white;
  }
  select,
  input {
    &::-webkit-input-placeholder {
      opacity: 0.3;
      font-weight: 300;
      font-size: 18px;
      color: blue;
      letter-spacing: 0;
      line-height: 32px;
    }
    &::-moz-placeholder {
      opacity: 0.3;
      font-weight: 300;
      font-size: 18px;
      color: blue;
      letter-spacing: 0;
      line-height: 32px;
    }
    &::-ms-placeholder {
      opacity: 0.3;
      font-weight: 300;
      font-size: 18px;
      color: blue;
      letter-spacing: 0;
      line-height: 32px;
    }
    &::placeholder {
      opacity: 0.3;
      font-weight: 300;
      font-size: 18px;
      color: blue;
      letter-spacing: 0;
      line-height: 32px;
    }
  }
  input {
    width: 100%;
    height: 60px;
    padding: 15px 15px;
    border-radius: 3px;
    border: 1px solid #dddddd;
    background: #ffffff;
  }
  label {
    color: #093e5f;
    font-family: 'Museo Sans', sans-serif;
    font-size: 16px;
    letter-spacing: 0.76px;
    font-weight: 500;
    margin: 0;
  }
  .my-courses {

    select {
      padding: 0 15px;
      border-radius: 3px;
      border: 1px solid #dddddd;
      -webkit-appearance: menulist-button;
      background: none !important;
    }

    select,
    input {
      height: 40px;
      width: 100%;
    }

    .nav-item {
      .nav-link {
        border: none;
        &.active {
          border-bottom: 2px solid #2199e8;
        }
      }
    }
    .big-white-box {
      padding-top: 20px;
      padding-bottom: 0;
      min-height: 500px;
      padding-left: 30px;
      overflow: scroll;
      &.no-overflow {
        overflow: hidden;
      }
      .table-brighton {
        min-width: 690px;
      }
    }
  }
  .big-white-box {
    background-color: white;
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .page-heading {
    height: 43px;
    color: #093e5f;
    font-family: 'Museo Sans', sans-serif;
    font-size: 30px;
    line-height: 43px;
    font-weight: 500;
    margin-bottom: 30px;
  }
  .table-heading {
    height: 15px;
    color: rgba(9, 62, 95, 0.5);
    font-family: 'Museo Sans', sans-serif;
    font-size: 13px;
    line-height: 15px;
  }
  .table-item {
    height: 60px;
    color: #093e5f;
    font-family: 'Museo Sans', sans-serif;
    font-size: 14px;
    line-height: 17px;
    align-items: center;
    display: flex;
    &.dark {
      background-color: #f7f9fa;
    }
    p {
      margin: 0;
    }
    .pillSuccess {
      background-color: rgba($color: #69ceb6, $alpha: 0.2);
      border-radius: 15px;
      text-align: center;
      color: #69ceb6;
      padding: 3px 0;
    }
    .pillPending {
      background-color: rgba($color: #ff6961, $alpha: 0.2);
      border-radius: 15px;
      text-align: center;
      color: #ff6961;
      padding: 3px 0;
    }
  }
  .dashMenu {
    a {
      width: 100%;
      text-decoration: none;
      border-bottom: 1px solid #f1f4f6;
      &:last-child {
        border-bottom: 0;
      }
    }
    .dashMenuItem {
      background-color: white;
      height: 60px;
      padding-left: 35px;
      color: #093e5f;
      font-family: 'Museo Sans', sans-serif;
      font-size: 16px;
      line-height: 19px;
      &.active {
        background-color: #f7f9fa;
        color: #2199e8;
      }
    }
  }
}
