@import '../../../variables.scss';

.autoSuggestWrapper {
  height: 72px;
  .react-autosuggest__container {
    position: relative;
    display: flex;
  }
  .react-autosuggest__suggestion {
  }
  input {
    &::-webkit-input-placeholder {
      opacity: 0.7;
      font-weight: 300;
      font-size: 18px;
      color: $fontDarkBlue;
      letter-spacing: 0;
      line-height: 32px;
      @include media-breakpoint-down(xs) {
        font-size: 15px;
      }
    }
    &::-moz-placeholder {
      opacity: 0.7;
      font-weight: 300;
      font-size: 18px;
      color: $fontDarkBlue;
      letter-spacing: 0;
      @include media-breakpoint-down(xs) {
        font-size: 15px;
      }
    }
    &::-ms-placeholder {
      opacity: 0.7;
      font-weight: 300;
      font-size: 18px;
      color: $fontDarkBlue;
      letter-spacing: 0;
      line-height: 32px;
      @include media-breakpoint-down(xs) {
        font-size: 15px;
      }
    }
    &::placeholder {
      opacity: 0.7;
      font-weight: 300;
      font-size: 18px;
      color: $fontDarkBlue;
      letter-spacing: 0;
      @include media-breakpoint-down(xs) {
        font-size: 15px;
      }
    }
  }
  .react-autosuggest__input {
    width: 90%;
    color: $fontDarkBlue;
    height: 60px;
    font-weight: 700;
    padding: 10px 20px;
    font-size: 18px;
    border: 1px solid rgba($color: #093e5f, $alpha: 0.4);
    border-radius: 4px;
    background-color: white;

    @media screen and (min-width: 999px) {
      width: 50%;
    }
  }
  .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .react-autosuggest__suggestions-container {
    display: none;
  }
  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 60px;
    background-color: #f1f4f6;
    color: $fontDarkBlue;
    font-weight: 700;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
    width: calc(90% + 20px);
    @media screen and (min-width: 999px) {
      width: calc(50% + 70px);
    }

    a {
      color: $darkBlue;
      padding-bottom: 8px;
      padding-top: 8px;
      display: block;
    }
  }
  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    list-style-type: none;
  }
  .react-autosuggest__suggestion {
    cursor: pointer;

    padding: 0px 20px 0px 20px;
  }
  .react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
  }
  .searchBtn {
    position: relative;
    top: -60px;

    left: calc(90% - 60px);

    @media screen and (min-width: 999px) {
      left: calc(50% - 10px);
    }
  }
}

// font-family: simple-line-icons;
// font-size: 20px;
// color: #FFFFFF;
// letter-spacing: 0;
// text-align: center;
// opacity: 0.3;
// font-family: MuseoSans-300;
// font-size: 18px;
// color: #093E5F;
// letter-spacing: 0;
// line-height: 32px;
