.btn-dashboard {
  height: 35px;
  width: 50%;
  float: right;
  padding: 3.5px 0;
  border-radius: 5px;
  background-color: #15b4f1;
  box-shadow: 0 4px 4px 0 rgba(21, 180, 241, 0.2);
  color: #ffffff;
  font-family: 'Museo Sans', sans-serif;
  font-size: 13px;
  border: none; }
